<script>
    import axios from "axios"
    import MyButton from '@/components/ui/MyButton.vue';

    export default {
    components: { MyButton },
        data: () => ({
            render: false,
            swiperTop: {
                allowTouchMove: true,
                slidesPerView: 1.9,
                centeredSlides: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                effect: 'coverflow',
                coverflowEffect: {
                    rotate: 1,
                    stretch: 96,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }
            },
            dataPromotions: [],
            dataCategories: [],
            bottomBanner: {},
        }),
        methods: {
            async getPromotions (){
                try{
                    /* const res = await axios.get(process.env.VUE_APP_SERVICE_URL + 'promociones-tienda'); */
                    const res = await axios.get(process.env.VUE_APP_SERVICE_URL + 'relevantes');
                    this.dataPromotions = res.data.filter(i => i.activo === 1);
                } catch (err) { console.log(err) }
            },
            async getCategories (){
                try{
                    const res = await axios.get(process.env.VUE_APP_SERVICE_URL + 'categorias-tienda');
                    this.dataCategories = res.data.filter(i => i.activo === 1);
                } catch (err) { console.log(err) }
            },
            async getBottomBanner (){
                try{
                    const res = await axios.get(process.env.VUE_APP_SERVICE_URL + 'banner-rasca-gana');
                    this.bottomBanner = res.data;
                } catch (err) { console.log(err) }
            },
            handleCategory(path){
                this.trackLog({ 
                    action: 'Click en categoría', 
                    medio: 'Botón', 
                    sectionId: 3, 
                    elementId: null, 
                })

                this.$router.push({ path: path });
            },
            handleClick(path){
                this.trackLog({ 
                    action: 'Click en producto desde lo mas relevante', 
                    medio: 'Botón', 
                    sectionId: 3, 
                    elementId: null, 
                })

                this.$router.push({ path: path });
            }
        },
        mounted() {
            this.getPromotions();
            this.getCategories();
            this.getBottomBanner();

            setTimeout(() => this.render = true, 0);

            this.trackLog({ 
                action: 'Ingreso a tienda', 
                medio: 'Botón', 
                sectionId: 3, 
                elementId: null, 
            })
        },
    }
</script>
<template>
    <div class="store-view">
        <div class="container">
            <div class="relative">
                <my-button class="mb-6" classBtn="btn-history ml-auto" label="Historial de compras" path="/historial-de-compras" link :sectionId="3" action="Ir a historial de compras"/>
                <p>
                    Lo más relevante del mes
                </p>
                    <!-- SWIPER -->
                    <swiper class="swiper mb-6" :options="swiperTop" ref="swiperTop" v-if="render">
                        <swiper-slide v-for="(item, idx) in dataPromotions" :key="idx">
                            <div class="item-layer relative z-0 flex align-end w-100pr h-31 relative bg-center bg-cover bg-no-repeat rounded-10 border-1 p-4" :style="{ 'background-image': 'url(' + item.imagen + ')' }" @click="handleClick(`/producto/${item.id_producto}`)">
                                <div class="max-w-75pr">
                                    <h3 class="tx-14 mb-0 tx-medium">
                                        {{ item.titulo }}
                                    </h3>
                                    <p class="tx-12 mb-0">
                                        {{ item.descripcion }}
                                    </p>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    
                    <!-- LAYER RESTRICT -->
                    <layer-restrict v-if="!$store.getters['isMember']"/>
                </div>
            </div>

            <!-- LIST -->
            <ul class="bullet-none p-0 mb-6"> 
                <div class="category-item flex align-center relative pl-20 py-2" v-for="(item, idx) in dataCategories" :key="idx" @click="handleCategory(item.sub_categoria === 1 ? `/subcategorias/${item.nombre}/${item.id}` : `/categoria/${item.id}/${item.nombre}/${item.sub_categoria}`)">
                    <div class="h-18 w-18 grid place-items-center mr-4 flex-none">
                        <img :src="item.icono" alt="">
                    </div>
                    <span class="tx-bold">
                        {{ item.nombre }}
                    </span>

                    <img class="decore-shine absolute l-0 b-0 -mb-3 z-1" src="@/assets/images/views/store/decore-shine.png" alt="">
                </div>
            </ul>
            <div class="relative">
                <a href="https://api.whatsapp.com/send?text=Comparte con tus amigos y vivan juntos la experiencia Zuggy Club Gamer. Solo da clic a la siguiente liga https://g-panel.zuggyclubgamers.com/rasca" target="_blank" data-action="share/whatsapp/share">
                    <img :src="bottomBanner.imagen" alt="">
                </a>
                
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>

    .item-layer{
        background-size: 100% 100%;
    }

    .category-item{
        background-color: rgba($color: #000000, $alpha: 0.2);
    }

    .btn-history{
        font-size: 10px!important;
    }
</style>