<script>
    export default {
        props: {
            data: Object
        },
        methods: {
            handleClick(path){
                this.trackLog({ 
                    action: 'Click en producto', 
                    medio: 'Botón', 
                    sectionId: 3, 
                    elementId: null, 
                })

                this.$router.push({ path: path });
            }
        }
    }
</script>
<template>
    <div class="product-item block relative p-3" @click="handleClick(`/producto/${data.id}`)">
        <div class="relative">
            <p class="tx-14 tx-bold mb-0" v-html="data.nombre"></p>
            <span class="tx-24 tx-medium opacity-03">
                {{ data.titulo }}
            </span>
            <div class="flex align-end">
                <p class="tx-center w-100pr mb-0 tx-10" v-html="data.promocion"></p>

                <!-- PRICE -->
                <div class="flex flex-column align-center w-fit -mt-10 ml-auto">
                    <span class="tx-24 tx-bold">
                        ${{ data.monto }}
                    </span>
                    <div class="relative btn h-7 w-22 rounded-10 mt-1">
                        Comprar
                        <img class="decore-shine absolute b-0" src="@/assets/images/layout/shine-btn.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <img class="decore-shine absolute b-0 -mb-1" src="@/assets/images/layout/shine-product-item.png" alt="">
    </div>
</template>
<style lang="scss" scoped>
    .product-item{
        background-color: rgba($color: #000000, $alpha: 0.2);
    }

    .btn{
        color: #fff;
        background-color: rgba($color: #fff, $alpha: 0.35);
        border: none;
    }
</style>

