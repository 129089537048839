import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import * as sdkclaro from "@claro/sdkclaro";
import axios from "axios";

console.log("ZuggyPS v58.2 29/oct")

sdkclaro.getInstance('ZuggyPS', 
  (apiKeySource, token, profileInformation, resourceInformation)=>{
    console.log('onLaunch') //handler para cuando la micro app finalice su carga
  },
  (eventName, eventInformation) =>{
    console.log("eventName UP");
    if(eventName === 'sendBootInformation'){
      console.log('eventInformation sendBootInformation')
      console.log(eventInformation) 
      console.log("pagoMembresia")
      console.log(this.pagoMembresia)
    }
    console.log('Onshow')
    console.log(eventInformation) 
  },
  ()=>{
    console.log('onHide') //handler para cuando la micro app se oculte
  },
  ()=>{
    console.log('onError') //handler para cuando suceda un error no controlado en las invocaciones ah el sdk
  },
  (eventName, eventInformation) => {
    console.log("eventName Down");
    console.log("eventName");
    console.log(eventName);
    console.log(eventInformation, "eventInformation")
    if (eventName === "sendBootInformation") {
      console.log("In sendBootInformation")
      //console.log(eventInformation.profileInformation.idOpen)
      localStorage.setItem('claro-id-open', eventInformation.profileInformation.idOpen)
    }
    if (eventName === 'responseRecharge') {
      //console.log(eventInformation, "Log responseRecharge");
      store.commit('modalAlertShopping');
      //console.log("Compra");
      //console.log(eventInformation.data)
      store.state.resultadoTransaccion = eventInformation.data
      store.dispatch('updatePoints')
      setTimeout(() => {
        store.dispatch('recoverPayment')
      }, 1000);
      //this.pago(eventInformation);
    }
    if (eventName === '_ONBACK_') {
      console.log("Presionando el boton back")
      window.history.back();
    }
  },
  (onReceiveEvent)=>{
    console.log('onReceiveEvent Fuera') //handler donde se recive eventos enviados a la micro app
    //console.log('Buscando sendBootInformation')
    //console.log(onReceiveEvent)
    if(onReceiveEvent==='sendBootInformation'){
      //console.log('Inicio sendBootInformation')
      console.log(onReceiveEvent)
      //console.log("Fin sendBootInformation")
    }
  }, 
{});

/* MY GLOBALS */
Vue.mixin({
    methods: {
        async trackLog(obj){
            const localAction      =   obj.action      ||  null;
            const localMedio       =   obj.medio       ||  null;
            const localSectionId   =   obj.sectionId   ||  null;
            const localElementId   =   obj.elementId   ||  null;

            let params = {
                "usuario_id": this.$store.getters['userId'],
                "accion": localAction,
                "medio": localMedio,
                "seccion_id": localSectionId,
                "elemento_id": localElementId,
            }

            try{
              const res = await axios.post(process.env.VUE_APP_SERVICE_URL + 'logs', params);
              console.log(res);
            } catch (err) { 
              console.log(err) 
            }

            console.log(params);
      },
    },
})

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import LayerRestrict from '@/components/layout/LayerRestrict.vue';

Vue.component('swiper', Swiper);
Vue.component('swiper-slide', SwiperSlide);
Vue.component('layer-restrict', LayerRestrict);

/* STYLE */
import "./scss/app.scss";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
