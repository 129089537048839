<script>
    import axios from 'axios';
    import ModalAlert from '@/components/ModalAlert.vue';

    export default {
        components: { ModalAlert },
        props: {
            data: Object
        },
        data: () => ({
            modal: false,
            restModal: true
        }),
        methods: {
            handleClick(path){
                this.trackLog({ 
                    action: 'Click en producto', 
                    medio: 'Botón', 
                    sectionId: 3, 
                    elementId: null, 
                })

                this.$router.push({ path: path });
            },
            async schedule(id){
                this.trackLog({ 
                    action: 'Me interesa el torneo con el id: ' + id, 
                    medio: 'Botón', 
                    sectionId: 1, 
                    elementId: this.$route.params.id, 
                })
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + 'registro-torneo?usuario_id='+ this.$store.getters['userId'] +'&torneo_id=' + id);
                    console.log(res.data)
                   
                    if(res.data.error == 1){
                        this.restModal = false
                    } else {
                        this.restModal = true
                    }
                    this.$emit('close')
                    this.modal = true
                    this.$emit('modalResultInfo', this.modal, this.restModal)
                    
                } catch (err) { console.log(err) }
                console.log("No entra")
            }
        }
    }
</script>
<template>
    <div class="product-item block relative p-3">
        <div class="relative">
            <p class="tx-14 tx-bold mb-0" v-html="data.titulo"></p>
            <span class="tx-14 tx-medium">
                <br>
                fecha: {{ data.fecha_torneo }}<br>
                premio: ${{ data.recompensa }}
            </span>
            <div class="flex align-end">
                <p class="tx-center w-100pr mb-0 tx-10" v-html="data.promocion"></p>

                <!-- PRICE -->
                <div class="flex flex-column align-center w-fit -mt-10 ml-auto">
                    <span class="tx-14 tx-medium">
                        costo:
                    </span>
                    <span class="tx-24 tx-bold">${{ data.costo }}
                    </span>
                    <div @click="schedule(data.id)" class="relative btn h-7 w-30 rounded-10 mt-1">
                        Saber más
                        <img class="decore-shine absolute b-0" src="@/assets/images/layout/shine-btn.png" alt="">
                    </div>
                </div>
            </div>

            <span class="tx-14 tx-bold item-a">Patrocinado por:<div class=" h-10 w-10 bg-cover bg-center bg-no-repeat rounded-100pr" :style="{ 'background-image': 'url(' + data.patrocinador + ')' }"></div></span>
            <p class="mt-5 tx-10 mb-0"><span class="tx-bold">Nota: </span>Para recibir la información es necesario que registres tu correo electrónico en tu perfil de ClaroPay.</p>
        </div>

        <img class="decore-shine absolute b-0 -mb-1" src="@/assets/images/layout/shine-product-item.png" alt="">
        
        <!-- LAYER RESTRICT -->
        <layer-restrict v-if="!$store.getters['isMember']"/>
    </div>
    
    
</template>
<style lang="scss" scoped>

    .item-a{
        display: flex;
        vertical-align: middle;
        align-items: center;
    }

    .item-a div {
        margin-left: 10px;
    }

    .product-item{
        background-color: rgba($color: #000000, $alpha: 0.2);
    }

    .btn{
        color: #fff;
        background-color: rgba($color: #fff, $alpha: 0.35);
        border: none;
    }
</style>

