<script>
    import axios from "axios"
    import MyButton from '@/components/ui/MyButton.vue';

    import { useVuelidate } from '@vuelidate/core'
    import { required, email } from '@vuelidate/validators'

    import * as sdkclaro from "@claro/sdkclaro";
    export default {
        mounted(){
            
            this.validateProfileClaroPay();
            this.validaProducto();
        },
        methods: {
            validaProducto(){
                console.log("Soy el producto");
                var data = '<?xml version="1.0" encoding="utf-8"?>\n<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">\n  <soap:Body>\n   <getStatusProduct>\n     <sku>799366539025</sku>\n     <trx_price>100</trx_price>\n   </getStatusProduct>\n  </soap:Body>\n</soap:Envelope>\n';
                var config = {
                    method: 'post',
                    url: 'https://webservice.zuggy.com:8443/ws_intercambio_variable.php',
                    headers: { 
                        'Content-Type': 'text/xml',
                        'Access-Control-Allow-Origin': '*'
                    },
                        data : data
                };
                axios(config)
                .then(function (response) {
                     console.log(JSON.stringify(response.data));
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            validateProfileClaroPay(){
                
                sdkclaro.getInstance().getTopic(
                    "getProfile",
                    "",
                (result)=>{
                    console.log(result)
                    try {
                        const res = result;
                        if (res != '') {
                            this.$store.state.userId = res.username;
                            this.$store.dispatch('checkMembership')
                            this.$store.dispatch('checkProfile')
                            localStorage.setItem('claro-pay-id', res.username)
                            this.$store.commit('setClaroPayId');
                            this.saveProfileClaroPay(result)
                        }
                    } catch (error) { console.log(error); }
                    
                },
                (error)=>{
                    console.log(error)
                })
            },
            saveProfileClaroPay(data){
                let params = {
                    curp : data.CURP,
                    ine : data.INE,
                    rfc : data.RFC,
                    alias : data.alias,
                    respuesta : data.answer,
                    fecha_nacimiento : data.birthDate,
                    lugar_nacimiento : data.birthPlace,
                    lugar_nacimiento_id : data.birthPlaceId,
                    ciudad : data.city,
                    ciudad_id : data.cityId,
                    pais : data.country,
                    created_at : data.creationDate,
                    dispositivo : data.device,
                    email : data.email,
                    numero_exterior : data.externalNumber,
                    nombre_padre_usuario : data.fathersName,
                    sexo_id : data.genderKey,
                    sexo : data.gendervalue,
                    //data.instabug,
                    numero_interior : data.internalNumber,
                    //data.isTelcel,
                    dok_id : data.keyDokId,
                    nombre_madre_usuario : data.mothersName,
                    numero_telefonico : data.msisdn,
                    nombre : data.name,
                    vecindario : data.neighborhood,
                    vecindarioId : data.neighborhoodId,
                    //opmActivation,
                    cp : data.postalCode,
                    imagen_perfil : data.profileImage,
                    pregunta : data.question,
                    segundo_nombre : data.secondName,
                    estado : data.state,
                    estado_id : data.stateId,
                    calle : data.street,
                    terminos : data.terms,
                    //updateFromOPM,
                    claro_pay_id : data.username,
                }
                axios.post(process.env.VUE_APP_SERVICE_URL + `saveProfile`, params).then(res => {
                    this.$router.replace({ path: '/inicio' })
                }).catch(error => { console.log(error) })
            },
            async handleSubmit(){
                const result = await this.v$.$validate()
                if (!result) return
            }
        }
    }
</script>
<template>
    <div class="login-view app-view pt-35">
    </div>
</template>